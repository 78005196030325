import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Header from "../components/organisms/header";
import HeaderM from "../components/organisms/headerM";
import Footer from "../components/organisms/footer";
import PretendardText from "../components/atoms/pretendardText";
import MainBtn from "../components/atoms/button/mainBtn";
import ConfirmModal from "../components/templates/modals/confirmModal";
import MainColorLayout from "../components/atoms/layouts/mainColorLayout";
import Input from "../components/atoms/input/input";
import MainLayout from "../components/atoms/layouts/mainLayout";
import { route } from "../routes/route";
import MainContainer from "../components/atoms/layouts/mainContainer";
import useDisplay from "../hooks/useDisplay";
import styled from "styled-components";
import FooterBtnGroup from "../components/organisms/footerBtnGroup";

function CareerTest(props) {
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const [position, setPosition] = useState(0);

  function onScroll() {
    setPosition(window.scrollY);
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <>
      <MainColorLayout>
        {isMobile ? <HeaderM main={false} /> : <Header main={false} />}

        <MainLayout>
          <MainContainer>
            <div
              style={{
                flex: 1,
                paddingBottom: 100,
                paddingTop: isMobile ? 20 : 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#555555",
                    fontWeight: 400,
                  }}
                >
                  홈
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#555555",
                    fontWeight: 400,
                    margin: "0px 15px",
                  }}
                >
                  ·
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#00B3CE",
                    fontWeight: 400,
                  }}
                >
                  진로적성검사
                </PretendardText>
              </div>

              <PretendardText
                style={{
                  fontSize: isMobile ? 32 : 60,
                  lineHeight: isMobile ? "44.8px" : "84px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: isMobile ? 50 : 100,
                  marginTop: isMobile ? 20 : 30,
                }}
              >
                진로적성검사
              </PretendardText>

              <PretendardText
                style={{
                  fontSize: 24,
                  lineHeight: "38.4px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: 10,
                }}
              >
                스스로 미래를 설계하고 구체화하는{isMobile ? <br /> : null} 진로
                설계 및 강화 프로그램
              </PretendardText>

              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "25.6px",
                  color: "#555555",
                  fontWeight: 400,
                  marginBottom: 30,
                }}
              >
                와이즈멘토의 특허 진단 프로그램을 통해{isMobile ? <br /> : null}{" "}
                파악된 성향을 바탕으로 진로를 탐색하고
                {isMobile ? <br /> : null} 학교생활에서 집중, 강조할 것에 대한
                방향을 제시합니다.
              </PretendardText>

              <img
                src={
                  isMobile
                    ? "/assets/images/careerTest_top01_m.png"
                    : "/assets/images/careerTest_top01.png"
                }
                alt="Career Test"
                style={{
                  width: isMobile ? "90vw" : "100%",
                  marginBottom: 100,
                }}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "start" : "center",
                  width: "100%",
                  justifyContent: "space-between",
                  marginBottom: 100,
                  minHeight: 340,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: isMobile ? 30 : 0,
                  }}
                >
                  {isMobile ? (
                    position > 400 ? (
                      <TextLeftAnimation
                        style={{
                          fontSize: 24,
                          lineHeight: "38.4px",
                          color: "#222222",
                          fontWeight: 700,
                          marginBottom: 10,
                        }}
                      >
                        입시에서 가장 중요한 것은
                        <br /> 타이밍이라고 강조하고 싶습니다.
                      </TextLeftAnimation>
                    ) : null
                  ) : position > 200 ? (
                    <TextLeftAnimation
                      style={{
                        fontSize: 24,
                        lineHeight: "38.4px",
                        color: "#222222",
                        fontWeight: 700,
                        marginBottom: 10,
                      }}
                    >
                      입시에서 가장 중요한 것은
                      <br /> 타이밍이라고 강조하고 싶습니다.
                    </TextLeftAnimation>
                  ) : null}

                  {isMobile ? (
                    position > 400 ? (
                      <TextLeftAnimation
                        style={{
                          fontSize: 16,
                          lineHeight: "25.6px",
                          color: "#555555",
                          fontWeight: 400,
                        }}
                      >
                        학생의 진로 탐색하는 과정이 반드시
                        {isMobile ? <br /> : null} 필요하며 구체적인 학과,
                        진로는 아니더라도 {isMobile ? null : <br />}
                        어떤 계열을
                        {isMobile ? <br /> : null} 희망하는지 알고 있어야
                        합니다. 그때부터 바로 입시의 시작입니다!
                      </TextLeftAnimation>
                    ) : null
                  ) : position > 200 ? (
                    <TextLeftAnimation
                      style={{
                        fontSize: 16,
                        lineHeight: "25.6px",
                        color: "#555555",
                        fontWeight: 400,
                      }}
                    >
                      학생의 진로 탐색하는 과정이 반드시
                      {isMobile ? <br /> : null} 필요하며 구체적인 학과, 진로는
                      아니더라도 {isMobile ? null : <br />}
                      어떤 계열을
                      {isMobile ? <br /> : null} 희망하는지 알고 있어야 합니다.
                      그때부터 바로 입시의 시작입니다!
                    </TextLeftAnimation>
                  ) : null}
                </div>

                {isMobile ? (
                  position > 500 ? (
                    <ImgAnimation
                      src={
                        isMobile
                          ? "/assets/images/careerTest_top02_m.png"
                          : "/assets/images/careerTest_top02.png"
                      }
                      alt="Career Test"
                      style={{
                        width: isMobile ? "90vw" : 616,
                        height: isMobile ? "auto" : 347,
                      }}
                    />
                  ) : null
                ) : position > 200 ? (
                  <ImgAnimation
                    src={
                      isMobile
                        ? "/assets/images/careerTest_top02_m.png"
                        : "/assets/images/careerTest_top02.png"
                    }
                    alt="Career Test"
                    style={{
                      width: isMobile ? "90vw" : 616,
                      height: isMobile ? "auto" : 347,
                    }}
                  />
                ) : null}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column-reverse" : "row",
                  alignItems: isMobile ? "start" : "center",
                  width: "100%",
                  justifyContent: "space-between",
                  marginBottom: 100,
                  minHeight: isMobile ? 0 : 340,
                }}
              >
                {isMobile ? (
                  position > 1100 ? (
                    <DivUPAnimation
                      style={{
                        backgroundColor: "#F7F7F7",
                        paddingTop: isMobile ? 52 : 0,
                        paddingBottom: isMobile ? 52 : 0,
                        paddingRight: isMobile ? 34 : 0,
                        paddingLeft: isMobile ? 34 : 0,
                        borderRadius: 20,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        width: isMobile ? "90vw" : 616,
                        height: isMobile ? "auto" : 347,
                      }}
                    >
                      <img
                        src={"/assets/images/careerTest_top03.png"}
                        alt="Career Test"
                        style={{
                          width: isMobile ? "100%" : 324,
                          height: isMobile ? "auto" : 297,
                        }}
                      />
                    </DivUPAnimation>
                  ) : null
                ) : position > 600 ? (
                  <DivUPAnimation
                    style={{
                      backgroundColor: "#F7F7F7",
                      paddingTop: isMobile ? 52 : 0,
                      paddingBottom: isMobile ? 52 : 0,
                      paddingRight: isMobile ? 34 : 0,
                      paddingLeft: isMobile ? 34 : 0,
                      borderRadius: 20,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      width: isMobile ? "90vw" : 616,
                      height: isMobile ? "auto" : 347,
                    }}
                  >
                    <img
                      src={"/assets/images/careerTest_top03.png"}
                      alt="Career Test"
                      style={{
                        width: isMobile ? "100%" : 324,
                        height: isMobile ? "auto" : 297,
                      }}
                    />
                  </DivUPAnimation>
                ) : null}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: isMobile ? 30 : 0,
                  }}
                >
                  {isMobile ? (
                    position > 900 ? (
                      <TextRightAnimation
                        style={{
                          fontSize: 24,
                          lineHeight: "38.4px",
                          color: "#222222",
                          fontWeight: 700,
                          marginBottom: 10,
                        }}
                      >
                        진로적성검사는 필수입니다.
                      </TextRightAnimation>
                    ) : null
                  ) : position > 600 ? (
                    <TextRightAnimation
                      style={{
                        fontSize: 24,
                        lineHeight: "38.4px",
                        color: "#222222",
                        fontWeight: 700,
                        marginBottom: 10,
                      }}
                    >
                      진로적성검사는 필수입니다.
                    </TextRightAnimation>
                  ) : null}

                  {isMobile ? (
                    position > 900 ? (
                      <TextRightAnimation
                        style={{
                          fontSize: 16,
                          lineHeight: "25.6px",
                          color: "#555555",
                          fontWeight: 400,
                        }}
                      >
                        진로적성검사를 통해 추후 전공을 이해하기 위한 교과를
                        {isMobile ? <br /> : null}
                        이수하여 {isMobile ? null : <br />} 대입에 활용될 로드맵
                        만들어갑니다.
                      </TextRightAnimation>
                    ) : null
                  ) : position > 600 ? (
                    <TextRightAnimation
                      style={{
                        fontSize: 16,
                        lineHeight: "25.6px",
                        color: "#555555",
                        fontWeight: 400,
                      }}
                    >
                      진로적성검사를 통해 추후 전공을 이해하기 위한 교과를
                      {isMobile ? <br /> : null}
                      이수하여 {isMobile ? null : <br />} 대입에 활용될 로드맵
                      만들어갑니다.
                    </TextRightAnimation>
                  ) : null}
                </div>
              </div>

              <div
                style={{ width: isMobile ? "89vw" : "100%", margin: "100px 0" }}
              >
                <PretendardText
                  style={{
                    fontSize: 24,
                    lineHeight: "38.4px",
                    color: "#222222",
                    fontWeight: 700,
                    marginBottom: 30,
                  }}
                >
                  프로그램 소개
                </PretendardText>
                <div
                  style={{
                    overflowX: "auto",
                    width: "100%",
                  }}
                >
                  <img
                    src={"/assets/images/careerTest_table_new.png"}
                    alt="프로그램 소개"
                    style={{
                      maxWidth: "1200px", // 이미지 너비 고정
                      whiteSpace: "nowrap",
                      marginBottom: "7px",
                    }}
                  />
                </div>
              </div>
              {/* <div
                style={{
                  width: isMobile ? "90vw" : "100%",
                }}
              >
                <div
                  style={{
                    overflowX: "auto",
                  }}
                >
                  <table
                    style={{
                      width: 1200,
                      height: 580,
                      borderTop: "1px solid #DDDDDD",
                      borderBottom: "1px solid #DDDDDD",
                      marginBottom: isMobile ? 30 : 0,
                    }}
                  >
                    <tr
                      style={{
                        height: 60,
                        backgroundColor: "#F7F7F7",
                      }}
                    >
                      <th
                        style={{
                          borderRight: "1px solid #DDDDDD",
                          width: 220,
                        }}
                      >
                        {" "}
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#222222",
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          대상
                        </PretendardText>
                      </th>
                      <th
                        style={{
                          width: 540,
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#222222",
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          초6 ~ 중3
                        </PretendardText>
                      </th>
                      <th
                        style={{
                          width: 540,
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#222222",
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          고1
                        </PretendardText>
                      </th>
                    </tr>

                    <tr
                      style={{
                        borderTop: "1px solid #DDDDDD",
                        height: 50,
                      }}
                    >
                      <td
                        style={{
                          borderRight: "1px solid #DDDDDD",
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#222222",
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          시기
                        </PretendardText>
                      </td>
                      <td
                        style={{
                          borderRight: "1px solid #DDDDDD",
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#555555",
                            fontWeight: 400,
                            textAlign: "center",
                          }}
                        >
                          상시 중3(12월)
                        </PretendardText>
                      </td>
                      <td>
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#555555",
                            fontWeight: 400,
                            textAlign: "center",
                          }}
                        >
                          예고1 (1월) ~ 고1 (3월)
                        </PretendardText>
                      </td>
                    </tr>
                    <tr
                      style={{
                        borderTop: "1px solid #DDDDDD",
                        height: 50,
                      }}
                    >
                      <td
                        style={{
                          borderRight: "1px solid #DDDDDD",
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#222222",
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          검사
                        </PretendardText>
                      </td>
                      <td
                        style={{
                          borderRight: "1px solid #DDDDDD",
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#555555",
                            fontWeight: 400,
                            textAlign: "center",
                          }}
                        >
                          학과선정검사 / 학습유형검사
                        </PretendardText>
                      </td>
                      <td>
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#555555",
                            fontWeight: 400,
                            textAlign: "center",
                          }}
                        >
                          학과선정검사 / 학습유형검사
                        </PretendardText>
                      </td>
                    </tr>
                    <tr
                      style={{
                        borderTop: "1px solid #DDDDDD",
                        height: 60,
                      }}
                    >
                      <td
                        rowSpan={3}
                        style={{
                          borderRight: "1px solid #DDDDDD",
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#222222",
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          회차
                        </PretendardText>
                      </td>

                      <td
                        style={{
                          borderRight: "1px solid #DDDDDD",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          <ul
                            style={{
                              marginBottom: 0,
                              paddingLeft: 20,
                            }}
                          >
                            <li>
                              <PretendardText
                                style={{
                                  fontSize: 16,
                                  lineHeight: "25.6px",
                                  color: "#555555",
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                사전검사(학생)
                              </PretendardText>
                            </li>
                          </ul>
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                              textAlign: "center",
                            }}
                          >
                            30~40분 소요
                          </PretendardText>
                        </div>
                      </td>

                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          <ul
                            style={{
                              marginBottom: 0,
                              paddingLeft: 20,
                            }}
                          >
                            <li>
                              <PretendardText
                                style={{
                                  fontSize: 16,
                                  lineHeight: "25.6px",
                                  color: "#555555",
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                온라인 검사 문자안내
                              </PretendardText>
                            </li>
                          </ul>
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                              textAlign: "center",
                            }}
                          >
                            30~40분 소요
                          </PretendardText>
                        </div>
                      </td>
                    </tr>
                    <tr
                      style={{
                        borderTop: "1px solid #DDDDDD",
                        height: 60,
                      }}
                    >
                      <td
                        style={{
                          borderRight: "1px solid #DDDDDD",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          <ul
                            style={{
                              marginBottom: 0,
                              paddingLeft: 20,
                            }}
                          >
                            <li>
                              <PretendardText
                                style={{
                                  fontSize: 16,
                                  lineHeight: "25.6px",
                                  color: "#555555",
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                1차 상담(학생) 검사결과안내 및 진로 관련
                                안내사항,Q&A
                              </PretendardText>
                            </li>
                          </ul>
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                              textAlign: "center",
                            }}
                          >
                            60분 소요
                          </PretendardText>
                        </div>
                      </td>

                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          <ul
                            style={{
                              marginBottom: 0,
                              paddingLeft: 20,
                            }}
                          >
                            <li>
                              <PretendardText
                                style={{
                                  fontSize: 16,
                                  lineHeight: "25.6px",
                                  color: "#555555",
                                  fontWeight: 400,
                                }}
                              >
                                1차 상담(학생&학부모) Report제공, 검사결과
                                종합안내
                                <br />
                                대입 전반 이해, Q&A
                              </PretendardText>
                            </li>
                          </ul>
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                              textAlign: "center",
                            }}
                          >
                            60~90분 소요
                          </PretendardText>
                        </div>
                      </td>
                    </tr>
                    <tr
                      style={{
                        borderTop: "1px solid #DDDDDD",
                        height: 60,
                      }}
                    >
                      <td
                        style={{
                          borderRight: "1px solid #DDDDDD",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          <ul
                            style={{
                              marginBottom: 0,
                              paddingLeft: 20,
                            }}
                          >
                            <li>
                              <PretendardText
                                style={{
                                  fontSize: 16,
                                  lineHeight: "25.6px",
                                  color: "#555555",
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                2차 상담(학부모) Report 제공, 검사결과 종합안내,
                                Q&A
                              </PretendardText>
                            </li>
                          </ul>
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                              textAlign: "center",
                            }}
                          >
                            60분 소요
                          </PretendardText>
                        </div>
                      </td>

                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          <ul
                            style={{
                              marginBottom: 0,
                              paddingLeft: 20,
                            }}
                          >
                            <li>
                              <PretendardText
                                style={{
                                  fontSize: 16,
                                  lineHeight: "25.6px",
                                  color: "#555555",
                                  fontWeight: 400,
                                }}
                              >
                                2차상담(학생) 내신대비 학습 법과 플래너 계획안내
                              </PretendardText>
                            </li>
                          </ul>
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                              textAlign: "center",
                            }}
                          >
                            40분 소요
                          </PretendardText>
                        </div>
                      </td>
                    </tr>

                    <tr
                      style={{
                        borderTop: "1px solid #DDDDDD",
                        height: 60,
                      }}
                    >
                      <td
                        rowSpan={4}
                        style={{
                          borderRight: "1px solid #DDDDDD",
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#222222",
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          Report
                        </PretendardText>
                      </td>

                      <td
                        style={{
                          borderRight: "1px solid #DDDDDD",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          <ul
                            style={{
                              marginBottom: 0,
                              paddingLeft: 20,
                            }}
                          >
                            <li>
                              <PretendardText
                                style={{
                                  fontSize: 16,
                                  lineHeight: "25.6px",
                                  color: "#555555",
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                학과선정검사 / 학습유형검사
                              </PretendardText>
                            </li>
                          </ul>
                        </div>
                      </td>

                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          <ul
                            style={{
                              marginBottom: 0,
                              paddingLeft: 20,
                            }}
                          >
                            <li>
                              <PretendardText
                                style={{
                                  fontSize: 16,
                                  lineHeight: "25.6px",
                                  color: "#555555",
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                학과선정검사 / 학습유형검사
                              </PretendardText>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr
                      style={{
                        borderTop: "1px solid #DDDDDD",
                        height: 60,
                      }}
                    >
                      <td
                        style={{
                          borderRight: "1px solid #DDDDDD",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          <ul
                            style={{
                              marginBottom: 0,
                              paddingLeft: 20,
                            }}
                          >
                            <li>
                              <PretendardText
                                style={{
                                  fontSize: 16,
                                  lineHeight: "25.6px",
                                  color: "#555555",
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                위 검사결과 분석을 바탕으로 한 진로 방향 모색
                              </PretendardText>
                            </li>
                          </ul>
                        </div>
                      </td>

                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          <ul
                            style={{
                              marginBottom: 0,
                              paddingLeft: 20,
                            }}
                          >
                            <li>
                              <PretendardText
                                style={{
                                  fontSize: 16,
                                  lineHeight: "25.6px",
                                  color: "#555555",
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                위 검사 결과 분석 바탕으로 한 학교 활동 가이드
                              </PretendardText>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr
                      style={{
                        borderTop: "1px solid #DDDDDD",
                        height: 60,
                      }}
                    >
                      <td
                        style={{
                          borderRight: "1px solid #DDDDDD",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          <ul
                            style={{
                              marginBottom: 0,
                              paddingLeft: 20,
                            }}
                          >
                            <li>
                              <PretendardText
                                style={{
                                  fontSize: 16,
                                  lineHeight: "25.6px",
                                  color: "#555555",
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                고등학교 진학시 학교활동 가이드
                              </PretendardText>
                            </li>
                          </ul>
                        </div>
                      </td>

                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          <ul
                            style={{
                              marginBottom: 0,
                              paddingLeft: 20,
                            }}
                          >
                            <li>
                              <PretendardText
                                style={{
                                  fontSize: 16,
                                  lineHeight: "25.6px",
                                  color: "#555555",
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                대입 준비를 위한 필수 입시 정보 제공
                              </PretendardText>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>

                    <tr
                      style={{
                        borderTop: "1px solid #DDDDDD",
                        height: 60,
                      }}
                    >
                      <td
                        style={{
                          borderRight: "1px solid #DDDDDD",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          <ul
                            style={{
                              marginBottom: 0,
                              paddingLeft: 20,
                            }}
                          >
                            <li>
                              <PretendardText
                                style={{
                                  fontSize: 16,
                                  lineHeight: "25.6px",
                                  color: "#555555",
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                최종 결정 또는 추천 학과에 도움이 되는 독서 추천
                              </PretendardText>
                            </li>
                          </ul>
                        </div>
                      </td>

                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          <ul
                            style={{
                              marginBottom: 0,
                              paddingLeft: 20,
                            }}
                          >
                            <li>
                              <PretendardText
                                style={{
                                  fontSize: 16,
                                  lineHeight: "25.6px",
                                  color: "#555555",
                                  fontWeight: 400,
                                  textAlign: "center",
                                }}
                              >
                                배정받은 학교 학업평가계획 분석을 통한 내신 대비
                                학습 공유
                              </PretendardText>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div> */}
            </div>
          </MainContainer>
        </MainLayout>

        <Footer />

        <FooterBtnGroup />
      </MainColorLayout>
    </>
  );
}

export default CareerTest;

const ImgAnimation = styled.img`
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }

  animation: fadeInUp 1s;
`;

const DivUPAnimation = styled.div`
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }

  animation: fadeInUp 1s;
`;

const TextLeftAnimation = styled(PretendardText)`
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }
  animation: fadeInLeft 1s;
`;

const TextRightAnimation = styled(PretendardText)`
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }

  animation: fadeInRight 1s;
`;
