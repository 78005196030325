import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Header from "../components/organisms/header";
import HeaderM from "../components/organisms/headerM";
import Footer from "../components/organisms/footer";
import PretendardText from "../components/atoms/pretendardText";
import MainBtn from "../components/atoms/button/mainBtn";
import ConfirmModal from "../components/templates/modals/confirmModal";
import MainColorLayout from "../components/atoms/layouts/mainColorLayout";
import Input from "../components/atoms/input/input";
import MainLayout from "../components/atoms/layouts/mainLayout";
import { route } from "../routes/route";
import MainContainer from "../components/atoms/layouts/mainContainer";
import useDisplay from "../hooks/useDisplay";
import Column from "antd/lib/table/Column";
import FooterBtnGroup from "../components/organisms/footerBtnGroup";

function CollegeEntrance(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainColorLayout>
        {isMobile ? <HeaderM main={false} /> : <Header main={false} />}

        <MainLayout>
          <MainContainer>
            <div
              style={{
                flex: 1,
                paddingBottom: 100,
                paddingTop: isMobile ? 20 : 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#555555",
                    fontWeight: 400,
                  }}
                >
                  홈
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#555555",
                    fontWeight: 400,
                    margin: "0px 15px",
                  }}
                >
                  ·
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#00B3CE",
                    fontWeight: 400,
                  }}
                >
                  대입
                </PretendardText>
              </div>

              <PretendardText
                style={{
                  fontSize: isMobile ? 32 : 60,
                  lineHeight: isMobile ? "44.8px" : "84px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: isMobile ? 50 : 100,
                  marginTop: isMobile ? 20 : 30,
                }}
              >
                대입준비
              </PretendardText>

              <PretendardText
                style={{
                  fontSize: 24,
                  lineHeight: "38.4px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: 10,
                }}
              >
                대학의 인재상에 부합하는 학교활동을 살펴보아야 합니다.
              </PretendardText>

              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "25.6px",
                  color: "#555555",
                  fontWeight: 400,
                  marginBottom: 30,
                }}
              >
                입시를 이해하기 위해선 대입에 대한 큰 흐름을 읽어야 합니다.
                {isMobile ? null : <br />} 첫 고등학교 입학과 함께 시작된 3년을
                계획해 보거나 남은 수험기간 동안 더 준비해야 할 것은 무엇이
                있는지 점검해야 합니다.
              </PretendardText>

              <img
                src={
                  isMobile
                    ? "/assets/images/collegeEntrance_top01_m.png"
                    : "/assets/images/collegeEntrance_top01.png"
                }
                alt="College Entrance"
                style={{
                  width: isMobile ? "90vw" : "100%",
                  marginBottom: 100,
                }}
              />

              <PretendardText
                style={{
                  fontSize: 24,
                  lineHeight: "38.4px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: 10,
                }}
              >
                프로그램 소개
              </PretendardText>

              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "25.6px",
                  color: "#555555",
                  fontWeight: 400,
                  marginBottom: 30,
                }}
              >
                시간이 흘러가는 대로 고등학교 3년의 생활을 보낼 것인지
                전략적으로 활동할지는 결과가 하늘과 땅 차이입니다. <br />
                에듀엣톡 대학 입시 프로그램과 함께하세요.
              </PretendardText>

              <div
                style={{
                  display: "flex",
                  width: isMobile ? 335 : 1200,
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: isMobile ? "start" : "space-between",
                  alignItems: "center",
                  alignSelf: "center",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    height: isMobile ? 730 : 1,
                    width: isMobile ? 1 : "100%",
                    backgroundColor: "#DDDDDD",
                    position: "absolute",
                    top: 99.5,
                  }}
                />

                <div
                  style={{
                    display: isMobile ? "none" : "inline",
                    height: 144,
                    width: 446,
                    position: "absolute",
                    border: "1px solid #DDDDDD",
                    top: 28,
                    left: 377,
                  }}
                />

                <img
                  src={
                    isMobile
                      ? "/assets/images/collegeEntrance_vector_d.png"
                      : "/assets/images/collegeEntrance_vector_r.png"
                  }
                  alt="College Entrance"
                  style={{
                    width: isMobile ? 12 : 6,
                    height: isMobile ? 6 : 12,
                    position: "absolute",
                    top: isMobile ? 115 : 94,
                    left: isMobile ? 161.5 : 175,
                  }}
                />

                <img
                  src={
                    isMobile
                      ? "/assets/images/collegeEntrance_vector_d.png"
                      : "/assets/images/collegeEntrance_vector_r.png"
                  }
                  alt="College Entrance"
                  style={{
                    width: isMobile ? 12 : 6,
                    height: isMobile ? 6 : 12,
                    position: "absolute",
                    top: isMobile ? 251 : 94,
                    left: isMobile ? 161.5 : 363,
                  }}
                />

                <img
                  src={
                    isMobile
                      ? "/assets/images/collegeEntrance_vector_d.png"
                      : "/assets/images/collegeEntrance_vector_r.png"
                  }
                  alt="College Entrance"
                  style={{
                    width: isMobile ? 12 : 6,
                    height: isMobile ? 6 : 12,
                    position: "absolute",
                    top: isMobile ? 502 : 94,
                    left: isMobile ? 161.5 : 832,
                  }}
                />

                <img
                  src={
                    isMobile
                      ? "/assets/images/collegeEntrance_vector_d.png"
                      : "/assets/images/collegeEntrance_vector_r.png"
                  }
                  alt="College Entrance"
                  style={{
                    width: isMobile ? 12 : 6,
                    height: isMobile ? 6 : 12,
                    position: "absolute",
                    top: isMobile ? 718 : 94,
                    left: isMobile ? 161.5 : 1020,
                  }}
                />

                <div
                  style={{
                    display: "flex",
                    width: isMobile ? 335 : 1200,
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: isMobile ? "start" : "space-between",
                    alignItems: "center",
                    alignSelf: "center",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: isMobile ? 335 : 160,
                      height: isMobile ? 100 : 180,
                      backgroundColor: "#F7F7F7",
                      border: "1px solid #DDDDDD",
                      borderRadius: 10,
                      marginBottom: isMobile ? 36 : 0,
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 16,
                        lineHeight: "25.6px",
                        color: "#222222",
                        fontWeight: 700,
                        textAlign: "center",
                      }}
                    >
                      진로적성검사를
                      <br />
                      통한 계열 확인
                    </PretendardText>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: isMobile ? 335 : 160,
                      height: isMobile ? 100 : 180,
                      backgroundColor: "#F7F7F7",
                      border: "1px solid #DDDDDD",
                      borderRadius: 10,
                      marginBottom: isMobile ? 36 : 0,
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 16,
                        lineHeight: "25.6px",
                        color: "#222222",
                        fontWeight: 700,
                        textAlign: "center",
                      }}
                    >
                      ‘학교생활기록부‘ <br />
                      진단과 분석을 통한 <br />
                      전략적인 준비
                    </PretendardText>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: isMobile ? 335 : 430,
                        height: isMobile ? 65 : 60,
                        backgroundColor: "#F7F7F7",
                        border: "1px solid #DDDDDD",
                        borderRadius: 10,
                        marginBottom: 10,
                      }}
                    >
                      <PretendardText
                        style={{
                          fontSize: 16,
                          lineHeight: "25.6px",
                          color: "#222222",
                          fontWeight: 700,
                          textAlign: "center",
                        }}
                      >
                        진로 계열에 따른 교과과정 확인
                      </PretendardText>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: isMobile ? 335 : 430,
                        height: isMobile ? 65 : 60,
                        backgroundColor: "#F7F7F7",
                        border: "1px solid #DDDDDD",
                        borderRadius: 10,
                        marginBottom: 10,
                      }}
                    >
                      <PretendardText
                        style={{
                          fontSize: 16,
                          lineHeight: "25.6px",
                          color: "#222222",
                          fontWeight: 700,
                          textAlign: "center",
                        }}
                      >
                        대학 인재상에 부합하는 학교활동 계획 수립 및 활동
                      </PretendardText>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: isMobile ? 335 : 430,
                        height: isMobile ? 65 : 60,
                        backgroundColor: "#F7F7F7",
                        border: "1px solid #DDDDDD",
                        borderRadius: 10,
                      }}
                    >
                      <PretendardText
                        style={{
                          fontSize: 16,
                          lineHeight: "25.6px",
                          color: "#222222",
                          fontWeight: 700,
                          textAlign: "center",
                        }}
                      >
                        학교생활기록부 기록 확인을 {isMobile ? <br /> : null}
                        통한 부족한 부분 보완 활동 수립
                      </PretendardText>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: isMobile ? 335 : 160,
                      height: 180,
                      backgroundColor: "#F7F7F7",
                      border: "1px solid #DDDDDD",
                      borderRadius: 10,
                      paddingTop: 15,
                      paddingBottom: 15,
                      marginTop: isMobile ? 36 : 0,
                      marginBottom: isMobile ? 36 : 0,
                    }}
                  >
                    <ul
                      style={{
                        paddingLeft: 10,
                      }}
                    >
                      <li>
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#222222",
                            fontWeight: 700,
                          }}
                        >
                          정량평가
                        </PretendardText>
                      </li>
                      <li>
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#222222",
                            fontWeight: 700,
                          }}
                        >
                          정성평가
                        </PretendardText>
                      </li>
                      <li>
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#222222",
                            fontWeight: 700,
                          }}
                        >
                          모의고사
                        </PretendardText>
                      </li>
                    </ul>
                    <PretendardText
                      style={{
                        fontSize: 16,
                        lineHeight: "25.6px",
                        color: "#222222",
                        fontWeight: 700,
                        textAlign: "center",
                      }}
                    >
                      분석을 통한
                      <br />
                      대학지원범위 확인
                    </PretendardText>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: isMobile ? 335 : 160,
                      height: isMobile ? 120 : 180,
                      backgroundColor: "#00B3CE",
                      borderRadius: 10,
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 16,
                        lineHeight: "25.6px",
                        color: "#FFFFFF",

                        fontWeight: 700,
                        textAlign: "center",
                      }}
                    >
                      누적 합격 DB 통한
                      <br />
                      합격권 예측
                    </PretendardText>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  height: 100,
                  borderBottom: "1px solid #DDDDDD",
                  marginBottom: 100,
                  alignSelf: "center",
                }}
              ></div>

              <PretendardText
                style={{
                  fontSize: 24,
                  lineHeight: "38.4px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: 30,
                }}
              >
                생기부레벨업
              </PretendardText>

              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignSelf: "center",
                  width: isMobile ? "100%" : 1200,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: 165,
                    border: "1px solid #DDDDDD",
                    borderRadius: 20,
                    marginRight: isMobile ? 0 : 20,
                    marginBottom: isMobile ? 20 : 0,
                    padding: 20,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 20,
                      lineHeight: "32px",
                      color: "#222222",
                      fontWeight: 700,
                      marginBottom: 5,
                    }}
                  >
                    1학년
                  </PretendardText>

                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#555555",
                      fontWeight: 400,
                    }}
                  >
                    1학년 진로적성검사 및 고등학교 생활 계획 수립 및 진행
                  </PretendardText>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: 165,
                    border: "1px solid #DDDDDD",
                    marginRight: isMobile ? 0 : 20,
                    marginBottom: isMobile ? 20 : 0,
                    borderRadius: 20,
                    padding: 20,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 20,
                      lineHeight: "32px",
                      color: "#222222",
                      fontWeight: 700,
                      marginBottom: 5,
                    }}
                  >
                    2학년
                  </PretendardText>

                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#555555",
                      fontWeight: 400,
                    }}
                  >
                    학교생활기록부 진단 분석 후 진행
                  </PretendardText>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: 165,
                    border: "1px solid #DDDDDD",
                    borderRadius: 20,
                    padding: 20,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 20,
                      lineHeight: "32px",
                      color: "#222222",
                      fontWeight: 700,
                      marginBottom: 5,
                    }}
                  >
                    3학년
                  </PretendardText>

                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#555555",
                      fontWeight: 400,
                    }}
                  >
                    대입 실전을 위한 1학기 학교활동 보완과 수시지원전략
                  </PretendardText>
                </div>
              </div>

              <PretendardText
                style={{
                  fontSize: 20,
                  lineHeight: "32px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: 20,
                  marginTop: isMobile ? 100 : 60,
                }}
              >
                맞춤형 1:1 코칭
              </PretendardText>

              <div
                style={{
                  width: isMobile ? "100%" : 1200,
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "Column",
                    marginRight: isMobile ? 0 : 20,
                    marginBottom: isMobile ? 30 : 0,
                  }}
                >
                  <img
                    src={
                      isMobile
                        ? "/assets/images/collegeEntrance_bottom01_m.png"
                        : "/assets/images/collegeEntrance_bottom01.png"
                    }
                    alt="College Entrance"
                    style={{
                      width: isMobile ? "90vw" : "100%",
                      marginBottom: 15,
                    }}
                  />
                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#222222",
                      fontWeight: 700,
                      marginBottom: 5,
                    }}
                  >
                    창의적체험활동 & 교과학습
                  </PretendardText>

                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#555555",
                      fontWeight: 400,
                      marginBottom: 5,
                    }}
                  >
                    진로에 따른 창의적체험활동영역과 개별 교과목의
                    <br />
                    탐구/실험/발표/보고서등 구체적 가이드제공
                  </PretendardText>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "Column",
                    marginRight: isMobile ? 0 : 20,
                    marginBottom: isMobile ? 30 : 0,
                  }}
                >
                  <img
                    src={
                      isMobile
                        ? "/assets/images/collegeEntrance_bottom02_m.png"
                        : "/assets/images/collegeEntrance_bottom02.png"
                    }
                    alt="College Entrance"
                    style={{
                      width: isMobile ? "90vw" : "100%",
                      marginBottom: 15,
                    }}
                  />
                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#222222",
                      fontWeight: 700,
                      marginBottom: 5,
                    }}
                  >
                    학생별 포트폴리오 관리
                  </PretendardText>

                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#555555",
                      fontWeight: 400,
                      marginBottom: 5,
                    }}
                  >
                    1년간 활동 기록을 통해 나의 포트폴리오 확인
                  </PretendardText>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "Column",
                  }}
                >
                  <img
                    src={
                      isMobile
                        ? "/assets/images/collegeEntrance_bottom03_m.png"
                        : "/assets/images/collegeEntrance_bottom03.png"
                    }
                    alt="College Entrance"
                    style={{
                      width: isMobile ? "90vw" : "100%",
                      marginBottom: 15,
                    }}
                  />
                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#222222",
                      fontWeight: 700,
                      marginBottom: 5,
                    }}
                  >
                    비대면상담
                  </PretendardText>

                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#555555",
                      fontWeight: 400,
                      marginBottom: 5,
                    }}
                  >
                    학생/학부모 대상 한 학기 마무리 상담
                  </PretendardText>
                </div>
              </div>
            </div>
          </MainContainer>
        </MainLayout>

        <Footer />

        <FooterBtnGroup />
      </MainColorLayout>
    </>
  );
}

export default CollegeEntrance;
